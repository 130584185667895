import React, { useState } from 'react';
import { AuthenticateDocument } from './AuthenticateDocument';
import External from './Comp/ExternalUser';
import MainLayout from './Comp/MainLayout';

export default function App({ isExternal }) {
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  if (isExternal) {
    return <External />;
  }
  else {
    if (isAuthenticating) { // isAuthenticating
      return <AuthenticateDocument setIsLoading={setIsAuthenticating} />
    }
    else {
      return <MainLayout />;
    }
  }
}
