import React, { createContext, useContext, useRef, useState } from "react";
import { useEdgesState, useNodesState } from "reactflow";
const MapContext = createContext();

export const MindMapContextProvider = ({ children }) => {
  const [documentInfo, setDocumentInfo] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [userColor, setUserColor] = useState('#000');
  const [darkMode, setDarkMode_] = useState(false);
  const [savingData, setSavingData] = useState({
    isSaving: '',
    lastSavedDateTime: "",
    fileSize: 0,
  });
  const [isDocumentChanged, setIsDocumentChanged] = useState(null);
  const reactFlowWrapper = useRef(null);
  const [isExternal, setIsExternal] = useState(false);
  const [isExternalAllow, setIsExternalAllow] = useState(false);

  return (
    <MapContext.Provider
      value={{
        documentInfo,
        isReadOnly,
        nodes,
        edges,
        selectedNodeId,
        reactFlowInstance,
        reactFlowWrapper,
        userInfo,
        userColor,
        darkMode,
        savingData,
        isDocumentChanged,
        isExternal,
        isExternalAllow,
        setDocumentInfo,
        setIsReadOnly,
        setNodes,
        setEdges,
        onEdgesChange,
        onNodesChange,
        setSelectedNodeId,
        setReactFlowInstance,
        setUserInfo,
        setUserColor,
        setDarkMode_,
        setSavingData,
        setIsDocumentChanged,
        setIsExternal,
        setIsExternalAllow,
      }}>
      {children}
    </MapContext.Provider>
  );
};

export const MindMapContext = () => {
  return useContext(MapContext);
};
