import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ResultComponent from './Comp/ResultComponent/ResultComponent';
import { MindMapContextProvider } from './Context/contex';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MindMapContextProvider>
      <Router>
        <Routes>
          <Route exact path="/:documentId" element={<App isExternal={false} />}></Route>
          <Route exact path="/external/:documentId" element={<App isExternal={true} />}></Route>
          <Route
            path="/invalid-document"
            element={<ResultComponent title="Document Verification Failed , Invalid Document. " />}
          />
          <Route
            path="/unauthorized"
            element={<ResultComponent title="Security Veification Failed , Unauthorized Access. " />}
          />
          <Route
            path="/no-internet"
            element={<ResultComponent title="No Internet, Check your internet connection." />}
          />
        </Routes>
      </Router>
    </MindMapContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
