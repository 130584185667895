import React, { useEffect, useRef, useState } from "react";
import BgColorDropdown from "../BgColorDropdown/bgDropdown";
import './userColor.css';
import { MindMapContext } from "../../Context/contex";
import { MindMapAPI } from "../../Api/api";
import { emitAllocatedColor, emitUpdateMultiple } from "../../Socket/socket";
import { debounce } from "lodash";
import { useParams } from "react-router-dom";


const UserColor = React.memo(() => {
  
  const { documentId } = useParams();
  const isMobile = window.screen.width < 768
  const { userColor, setUserColor, setNodes, userInfo, nodes, setSavingData, setIsDocumentChanged } = MindMapContext();
  const [nodeBg, setNodeBg] = useState(null);

  useEffect(() => {
    setUserColor(nodeBg);
    if (nodeBg) {
      setSavingData({ isSaving: 'Saving...', lastSavedDateTime: '' });
      setNodes((nds) =>
        nds.map((node) => {
          if (node.creatorId === userInfo.userId && node.data.allocatedColor !== nodeBg) {
            node.data.allocatedColor = nodeBg;
          }
          return node;
        })
      );
      updateBorderColor(nodes, { nodeBg: nodeBg, userId: userInfo.userId,documentId: documentId });
    }
  }, [nodeBg]);

  const updateBorderColor = useRef(debounce((nodes, payload) => {
    MindMapAPI.updateMany(nodes);
    emitAllocatedColor(payload);
    setIsDocumentChanged(true);
  }, 400)).current;

  return (
    <div className="color-btn" style={{ backgroundColor: `${userColor}`, width: isMobile ? 20 : 30, height: isMobile ? 20 : 30 }}>
      <BgColorDropdown setNodeBg={setNodeBg} isIconVisible={false} />
    </div>
  );
})

export default UserColor;
