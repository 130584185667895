import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 } from "uuid";
import MainLayout from '../MainLayout';
import Loader from '../SkeletonLoader/Loader';
import { MindMapAPI } from '../../Api/api';
import { MindMapContext } from '../../Context/contex';

const External = () => {
    const { documentId } = useParams();
    const navigate = useNavigate();
    const {isExternal , setIsExternal, setUserInfo, setDocumentInfo, setIsReadOnly } = MindMapContext();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                const result = await MindMapAPI.verifyExternal(documentId);
                setIsExternal(true);
                setUserInfo({
                    userId: `external-${v4()}`,
                    email: 'N/A',
                    name: 'External User'
                });
                setIsReadOnly(true);
                setDocumentInfo(result?.data);
            } catch (error) {
                console.error("API error:", error);
                navigate('/unauthorized');
            } finally {
                setLoading(false);
            }
        })();
    }, [documentId]);

    return loading ? <Loader /> : isExternal ? <MainLayout /> : null;
};

export default External;
