
const hardcodeToken = null;

function getCookieValue(name) 
    {
      const regex = new RegExp(`(^| )${name}=([^;]+)`)
      const match = document.cookie.match(regex)
      if (match) {
        return match[2]
      }
   }

export function getWorkWiseAuthToken() {
    const searchParams = new URLSearchParams(document.location.search);
    let cookieToken = getCookieValue('token');
    let queryToken = searchParams.get("token");
    const finalToken = queryToken || cookieToken || hardcodeToken;
    return finalToken;
}
export const formatFileSize = (bytes) => {
  if (bytes === 0) return "0 Bytes";

  const units = ["Bytes", "KB", "MB", "GB"];
  let size = bytes;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  if (unitIndex === 0) {
    return `${size} ${units[unitIndex]}`;
  } else if (unitIndex === 1 && size < 50) {
    return `${parseFloat(size.toFixed(0))} ${units[unitIndex]}`;
  } else {
    return `${parseFloat(size.toFixed(2))} ${units[unitIndex]}`;
  }
};
export const isRespectiveDocument = (documentId) => {
  const pathname = window.location.pathname;
  const id = pathname.split("/")[1];
  return id && id === documentId;
};

