import React from 'react';
import { useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import Title from '../Header/Header';
import UserDetails from '../UserDetail/UserDetails';
import MindMapContainer from '../MindMapContainer/MindMapContainer';
import { ChatBox } from '../ChatBox/ChatBox';
import SocketListener from '../SokcetListner/socketLister';

const MainLayout = () => {
     const { documentId } = useParams();
    return (
        <div>
            <ReactFlowProvider>
                <div style={{ display: "flex", justifyContent: "space-between", }} className='my-header'>
                    <Title />
                    <UserDetails />
                </div>
                <div style={{ marginTop: '6px', display: 'flex' }}>
                    <MindMapContainer />
                </div>
            </ReactFlowProvider>
            <div className='chatbox-container'>
                <ChatBox id={documentId} />
            </div>
            <SocketListener />
        </div>
    )
}

export default MainLayout;