import React, { useEffect, useState } from 'react'
import { NodeResizer } from 'reactflow';
import { MindMapContext } from '../../Context/contex';
import { MindMapAPI } from '../../Api/api';
import { emitNodeUpdate } from '../../Socket/socket';
import { useParams } from 'react-router-dom';

const Resizer = ({ isSelected, setHeight, setWidth, inputStyle, setisResize, isResize }) => {
  const { documentId } = useParams();
  const { isReadOnly, selectedNodeId, setNodes,setIsDocumentChanged} = MindMapContext();
  const [nodePosition, setNodePosition] = useState({});

  const handleResize = (e, newSize) => {
    if (isResize === false) {
      setisResize(true);
    }
    setWidth(newSize.width);
    setHeight(newSize.height);

  };
  const handleResizeEnd = (e, node) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === selectedNodeId) {
          node.data = {
            ...node.data,
            width: node.width,
            height: node.height
          };
          setNodePosition(node.position);
        }
        return node;
      })
    );
    MindMapAPI.updateNode(selectedNodeId, { style: { width: node.width, height: node.height } });
    let nodePosition = node
    let payload = {
      id: selectedNodeId,
      nodePosition: nodePosition,
      style: { width: node.width, height: node.height },
      documentId: documentId,
    }
    emitNodeUpdate(payload);
    setIsDocumentChanged(true);
  }
  useEffect(() => {
    const errorHandler = (e) => {
      if (
        e.message.includes(
          "ResizeObserver loop completed with undelivered notifications" ||
          "ResizeObserver loop limit exceeded"
        )
      ) {
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.style.display = "none";
        }
      }
    };
    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);
  return (
    <>
      <div key={selectedNodeId}>
        <NodeResizer
          color="#ff0071"
          isVisible={isReadOnly ? false : isSelected}
          onResize={handleResize}
          onResizeEnd={handleResizeEnd}
          style={inputStyle}
        />
      </div>
    </>
  )
}

export default Resizer;