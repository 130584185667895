import { io } from "socket.io-client";

 const URL = "https://map.workw.com/";
// const URL = 'http://192.168.18.98:3000'

//Added local Socket Server for Testing
// const URL = "http://localhost:3000";
const socket = io(URL);
// emitters
export const emitRoomID=(data)=>{
    socket.emit('joinDocument',data)
}
export const emitNodeAdd = (data) => {
    // console.log("okk", "EMIT NODE ADD")
    socket.emit("node-added", data);
};
//Socket Emitter for Chat
export const emitLiveComments = (data) => {
    socket.emit("comments", data);
};
//Socket Listener for Chats
export const listenerLiveComments = (updateMessageList) => {
    socket.on("comments", (data) => {
      data.color = "#98b3e6";
      data.margin = "5px auto 5px 0px"; //this styling work should be done through id
      updateMessageList(data);
    });
  };

export const listenerLiveCommentsOff = () => {
    socket.off("comments");
};
  
export const listenNodeAdd = (callback) => {
    socket.on("node-added", (data) => {
        // console.log("okk", "LISTNER NODE ADD")
        callback(data);
    });
};
export const listenNodeAddOff = () => {
    socket.off("node-added");
};

export const emitNodeDelete = (data) => {
    socket.emit("node-remove", data);
    // console.log("okk", "emitNodeDelete")
};
export const listenNodeDelete = (callback) => {
    socket.on("node-remove", (data) => {
        // console.log("okk", "listenNodeDelete")
        callback(data);
    });
};
export const listenNodeDeleteOff = () => {
    socket.off("node-remove");
};

export const emitEdgeAdd = (data) => {
    socket.emit("edge-added", data);
    // console.log("okk", "EMIT EDGE ADD")
};

export const listenEdgeAdd = (callback) => {
    socket.on("edge-added", (data) => {
        // console.log("okk", "LISTEN EDGE ADD")
        callback(data);
    });
};
export const listenEdgeAddOff = () => {
    socket.off("edge-added");
};

export const emitEdgeDelete = (data) => {
    // console.log('okkkkReceived Emit Remove:', data);

    socket.emit("edge-remove", data);
};
export const listenEdgeDelete = (callback) => {
    socket.on("edge-remove", (data) => {
        callback(data);
    });
};
export const listenEdgeDeleteOff = () => {
    socket.off("edge-remove");
};

export const emitNodeUpdate = (data) => {
    // console.log("okk", "emitNodeUpdate")
    socket.emit("node-update", data);
};
export const listenNodeUpdate = (callback) => {
    socket.on("node-update", (data) => {
        // console.log("okk", "listenNodeUpdate")
        callback(data);
    });
};
export const listenNodeUpdateOff = () => {
    socket.off("node-update");
};


//Multiple Data is sent viia event to Server
export const emitUpdateMultiple = (data) => {

    socket.emit("update-multiple", data);
};
//Multiple Data is Listened via event from Server

export const listenUpdateMultiple = (callback) => {

    socket.on("update-multiple", (data) => {
        // console.log("okk", "listenNodeUpdate")
        callback(data);
    })
};

export const emitAllocatedColor = (data) => {

    socket.emit("allocated-color-change", data);
};
export const listenAllocateColor = (callback) => {

    socket.on("allocated-color-change", (data) => {
        // console.log("okk", "listenNodeUpdate")
        callback(data);
    })
};

export default socket;
