import { Button, message } from "antd";
import classes from "./SharePrivacy.module.css";
// import "antd/dist/antd.css";
import { DropDownMenu } from "./DropDownMenu/DropDownMenu";
import ShareForm from "./ShareForm";
import { MindMapAPI, workwiseServices } from "../../../Api/api";
import { MindMapContext } from "../../../Context/contex";
import { useParams } from "react-router-dom";
import AvatarComp from "../../Avatar";


export const SharePrivacy = ({ contentToggle, setIsModalVisible, privacy, setPrivacy }) => {
  const { documentInfo, setDocumentInfo, isExternalAllow, setIsExternalAllow } = MindMapContext()
  const isMobile = window.screen.width < 769;


  const mileGridName = documentInfo.name;

  const { documentId } = useParams()
  const id = documentId;
  const privacyId = 1
  const payload = { attachmentId: id, name: mileGridName, privacyId }

  const callShareDocumentToAnyone = async () => {
    try {
      await workwiseServices.shareDocumentToAnyone(payload);
      message.success("Document is shared as Public");
      setIsModalVisible(false);
      const response = await workwiseServices.getDocumentById(id);
      if (response?.status === 200 && response.data?.data?.rightType !== 0) {
        setDocumentInfo(response.data.data);
      }
      await MindMapAPI.updateDocumentPrivacy({ documentId: id, isExternal: true, name: documentInfo?.name });
      setIsExternalAllow(true);
    } catch (error) {
      console.error("Error sharing document:", error);
      message.error("Failed to share document. Please try again.");
    }
  };

  const handleRemoveAccess = async () => {
    try {
      await MindMapAPI.updateDocumentPrivacy({
        documentId: id,
        isExternal: false,
        name: documentInfo?.name
      });
      setIsExternalAllow(false);
      message.success("External access removed successfully!");
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error removig document access:", error);
      const errorMessage = error.response?.data?.message || "Failed to remove access.";
      message.error(errorMessage);
    }
  };
  return (
    <div className={classes.ownerInfo}>
      {
        privacy === 0 &&
        <div className={classes.avatar}>
          <AvatarComp />
        </div>
      }
      <div className={classes.info}>
        <div className={classes.name}>
          {contentToggle ? (
            <h4>Name</h4>
          ) : (
            <DropDownMenu
              menuItems={[{ label: "Restricted", key: 1 }, { label: "Anyone", key: 0 }]}
              placement="bottomLeft"
              type={privacy}
              setType={setPrivacy}
            />
          )}
        </div>
        <div className={classes.email}>
          {privacy === 1 ? (
            <>
              <ShareForm setIsModalVisible={setIsModalVisible} setPrivacy={setPrivacy} />
            </>
          ) : (
            <div className={classes.publicOptionContainer}>
              <p>Anyone with the link can access
              </p>
              <div className={classes.publicShareBtnsContainer}>
                <Button
                  onClick={callShareDocumentToAnyone}
                  className={`${classes.shareBtn}`}
                  size="middle"
                  htmlType="submit"
                  style={{ width: isExternalAllow ? '140px' : 'fit-content' }}
                  disabled={isExternalAllow}
                >
                  Make public
                </Button>
                {isExternalAllow &&
                  <Button
                    size="middle"
                    className={`${classes.removeAccessBtn}`}
                    htmlType="submit"
                    onClick={handleRemoveAccess}
                  >
                    Remove external
                  </Button>
                }
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
