import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, message } from 'antd';
import { MindMapContext } from '../../Context/contex';
import { debounce } from "lodash";
import { MindMapAPI } from '../../Api/api';
import { emitNodeUpdate } from '../../Socket/socket';
import { useParams } from 'react-router-dom';

const { TextArea } = Input;

const NodeInput = ({ data, inputStyle }) => {
  const { documentId } = useParams();
  const { isReadOnly, setNodes, setSavingData, selectedNodeId, setIsDocumentChanged, nodes } = MindMapContext();
  const [nodeName, setNodeName] = useState(data.label);

  useEffect(() => {
    setNodeName(data.label);
  }, [data.label]);

  const updateNodeName = useRef(debounce((selectedNodeId, payload) => {
    MindMapAPI.updateNode(selectedNodeId, payload)
      .then(() => setIsDocumentChanged(true))
      .catch((error) => {
        message.error('Error updating node');
        console.error('Error updating node:', error);
      });

    let emitPayload = {
      id: selectedNodeId,
      name: payload?.data?.label,
      documentId: documentId,
    };
    emitNodeUpdate(emitPayload);
  }, 400)).current;

  // Clean up debounce on unmount
  useEffect(() => {
    return () => {
      updateNodeName.cancel();
    };
  }, [updateNodeName]);

  const handleChange = (event) => {
    setSavingData({ isSaving: 'Saving...', lastSavedDateTime: '' });
    const newValue = event.target.value;
    let updatedNodeData = null;
    const updatedNodes = nodes.map((node) => {
      if (node.id === selectedNodeId) {
        updatedNodeData = {
          ...node.data,
          label: newValue,
        };
        return {
          ...node,
          data: updatedNodeData,
        };
      }
      return node;
    });
  
    if (updatedNodeData) {
      const payload = { data: updatedNodeData };
      setNodes(updatedNodes);
      updateNodeName(selectedNodeId, payload);
    }

  };
  const handleTextAreaFocus = (event) => {
    event.target.setSelectionRange(0, 0);
  };

  return (
    <Form>
      <Form.Item>
        <TextArea
          placeholder="Enter text"
          value={nodeName}
          onChange={handleChange}
          onFocus={handleTextAreaFocus}
          autoSize={{ minRows: 2 }}
          readOnly={isReadOnly}
          style={{
            ...inputStyle,
            textAlign: 'center',
            padding: '0',
            border: 'none',
            outline: 'none',
            boxShadow: 'none',
            backgroundColor: 'transparent',
            color: data.textBg,
          }}
        />
      </Form.Item>
    </Form>
  );
};

export default NodeInput;
